<template>
    <div>
        <div>
            <div class="gerenrz">个人实名认证</div>
            <!-- <div style="background: #f6f6f6;height: 10px;"></div> -->
            <van-form class="resident" ref='form'>
                <div class="fangshirs">认证方式</div>
                <div class="shenfenzheng" style="background: #e1eeff;border: 1px solid #1872ed;" id="shenfenzheng" @click="genhuanlist(1)">
                    <img class="sftu" src="../../../assets/images/shenfenzhengtu.png" alt="">
                    <div class="fenfenlist">身份证认证</div>
                    <img class="xuanzhonglist" id="xuanzhong" src="../../../assets/images/xuanzhongsf.png" alt="">
                    <img class="xuanzhonglist" id="weixuanzhong" style="display: none;" src="../../../assets/images/meixuanchong.png" alt="">
                </div>
                <div class="shenfenzheng" id="renlianshibie" @click="genhuanlist(2)">
                    <img class="sftu" src="../../../assets/images/renliantu.png" alt="">
                    <div class="fenfenlist">人脸认证</div>
                    <img class="xuanzhonglist" id="xuanzhongyi" style="margin-left: 205px;" src="../../../assets/images/meixuanchong.png" alt="">
                    <img class="xuanzhonglist" id="weixuanzhongyi" style="margin-left: 205px;display: none;" src="../../../assets/images/xuanzhongsf.png" alt="">
                </div>
                <div class="fangshirs">真实名字</div>
                <van-field class="shuruys" v-model="valuename" placeholder="请输入真实姓名" :rules="name" />
                <div class="fangshirs">证件号</div>
                <van-field
                class="shuruys"
                v-model="idcard"
                name="身份证号"
                placeholder="请输入身份证号"
                :rules="[{ required: true, message: '请填写身份证号' },{validator, message: '身份证号码格式错误！'}]"
                />
                <!-- 手机号码 -->
                <div v-if="id == 1" class="fangshirs">手机号</div>
                <van-field class="shuruys" v-if="id == 1" placeholder="请输入手机号码" v-model='mobile' disabled ></van-field>
                <!-- 验证码 -->
                <div v-if="id == 1" class="fangshirs">验证码</div>
                <van-field class="shuruys" v-if="id == 1" placeholder="请输入验证码" v-model="code" :rules="codeRules">
                    <!-- 通过 button 插槽可以在输入框尾部插入按钮 -->
                    <template #button>
                        <!-- 这里有bug，使用<van-button>无法进行局部表单验证 -->
                        <!-- <van-button size="small" type="primary" @click="getCode">发送验证码</van-button> -->
                        <div v-if="codeShow" class="button" style="color: #1872ED;" @click="getPhoneCode">获取验证码</div>
                        <span v-if="!codeShow" class="count" style="margin-right: 10px;">{{count}}秒后重试</span>
                    </template>
                </van-field>
                <div v-if="id == 1" class="tijiaoanniu" @click="quedinglist()">确 定</div>
                <div v-if="id == 2" class="tijiaoanniu" @click="xiayibulist()">下一步</div>
            </van-form>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: "RealnameAuthentication",
    computed:{
		...mapGetters({
			org_id:'user/getOrgId'
		})
	},
    data() {
        return {
            loading: false,
            radio: '1',
            valuename: '', //姓名
            idcard: '', //身份证号
            mobile: '', // 手机号码
            code: '', // 验证码
            sms: '',
            codeShow: true,
            count: '',
            timer: null,
            jichushow: false,
            id: 1,
            codeRules: [{
                required: true,
                message: '验证码不能为空',
                trigger: 'onBlur'
            }],
            name: [{
                required: true,
                message: '姓名不能为空',
                trigger: 'onBlur'
            }]
        }
    },
    created() {
        this.getUserInfo()
    },
    methods: {
        genhuanlist(id) {
          let elemt = document.getElementById('shenfenzheng');
          let elemt1 = document.getElementById('renlianshibie');
          let xuanzhong = document.getElementById('xuanzhong');
          let weixuanzhong = document.getElementById('weixuanzhong');
          let xuanzhongyi = document.getElementById('xuanzhongyi');
          let weixuanzhongyi = document.getElementById('weixuanzhongyi');
          if(id == 1) {
            elemt.style.backgroundColor = '#e1eeff'
            elemt1.style.backgroundColor = '#f8fafe'
            elemt.style.border = '1px solid #1872ed'
            elemt1.style.border = '1px solid #e6e9f0'
            xuanzhong.style.display='block'
            weixuanzhong.style.display='none'
            xuanzhongyi.style.display='block'
            weixuanzhongyi.style.display='none'
          } else {
            elemt.style.backgroundColor = '#f8fafe'
            elemt1.style.backgroundColor = '#e1eeff'
            elemt.style.border = '1px solid #e6e9f0'
            elemt1.style.border = '1px solid #1872ed'
            xuanzhongyi.style.display='none'
            weixuanzhongyi.style.display='block'
            xuanzhong.style.display='none'
            weixuanzhong.style.display='block'
          }
          this.id = id
        },
        async getUserInfo(){
            // 查询用户实名
            const res = await this.$api.post('/user/index/getUserInfo')
            if(res.data.code === 200){
                this.mobile = res.data.data.phone
            }
        },
        xiayibulist() {
            if(this.loading) {
                return
            }
            this.loading = true
            this.$refs.form.validate().then(() => {
                const data = {
                    card: this.idcard,
                    name: this.valuename
                }
                this.$api.post('/user/app/faceAuthentication', data)
                .then(res=>{
                    if(res.data.code == 200) {
                        console.log('333333-------', res.data.data)
                        window.location.href = res.data.data
                    } else {
                        this.$toast.fail(res.data.message)
                    }
                    this.loading = false
                })
                        // this.$toast.success('提交成功')
                        // this.$router.push({
                        //     path: "/FaceResults",
                        //     query: {
                        //         chenggongid: 2
                        //     }
                        // })
                    }).catch(() => {
                        this.$toast.fail('提交失败')
                        this.loading = false
                    })
        },
        quedinglist() {
            if(this.loading) {
                return
            }
            this.loading = true
            this.$refs.form.validate().then(() => {
                const data = {
                    card: this.idcard,
                    name: this.valuename,
                    mobile: this.mobile,
                    code: this.code
                }
                this.$api.post('/user/app/cardAuthentication', data)
                .then(res=>{
                    console.log('sgxxxxxxxxxxx', res)
                    if(res.data.code == 200) {
                        this.$toast.success('提交成功')
                        this.$router.push({
                            path: "/Realnameresult",
                            query: {
                                chenggongid: 2
                            }
                        })
                    } else {
                        this.$toast.fail(res.data.message)
                        this.$router.push({
                            path: "/Realnameresult",
                            query: {
                                name: this.valuename,
                                idcard: this.idcard,
                                mobile: this.mobile,
                                chenggongid: 1,
                                namejieguo: res.data.data
                            }
                        })
                    }
                    this.loading = false
                })
                    }).catch(() => {
                        this.$toast.fail('提交失败')
                        this.loading = false
                    })
        },
        getPhoneCode() {  //获取短信验证码
            const data = {
                phone: this.mobile,
                area: 86,
                org_id: this.org_id
            }
            this.$api.post('/common/index/sendSms',data)
            .then(res=>{
                if(res.data.code == 200) {
                    var Reg = /^[1][34578][0-9]{9}$/;
            // 正则验证
            if (Reg.test(this.mobile)) {
                this.$toast('验证码发送成功');
                const TIME_COUNT = 60;
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.codeShow = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                        } else {
                        this.codeShow = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        }
                    }, 1000)
                }
                // let code = {
                //     mobile: this.mobile,
                //     sms_type: "login"
                // }
                // getSmsCode(code).then(res => {
                //     console.log(res)
                // })
            } else {
                this.$toast('手机号码格式不正确');
            }
                } else {
                    this.$toast(res.data.message);
                }
            })
            
        },
        validator (val) {
        const card15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/
        const card18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        return card15.test(val) || card18.test(val)
        }
    }
}
</script>
<style scoped>
::v-deep .van-field__control::-webkit-input-placeholder {
  color: #9b9da9;
}
.xuanzhonglist {
    height: 20px;
    margin: auto 0;
    margin-left: 191px;
}
.fenfenlist {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    margin-left: 10px;
}
.sftu {
    height: 20px;
    margin-left: 16px;
    margin-top: 22px;
}
.shenfenzheng {
    width: 343px;
    height: 64px;
    background: #f8fafe;
    border: 1px solid #e6e9f0;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 14px;
    display: flex;
    line-height: 64px;
}
.button {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #1872ed;
    margin-right: 10px;
}
.shuruys >>> .van-field__control {
    padding-left: 10px;
    /* color: #999999; */
}
.shuruys >>> .van-field__error-message {
    width: 343px;
    margin: 0 auto;
}
.resident >>> .van-cell::after {
    border: none;
}
.shuruys >>> .van-field__body {
    width: 343px;
    height: 40px;
    background: #f8fafe;
    border: 1px solid #e6e9f0;
    border-radius: 6px;
    margin: 0 auto;
}
.tijiaoanniu {
    width: 343px;
    /* margin-left: 16px;
    margin-right: 16px; */
    height: 42px;
    background: #1872ed;
    border-radius: 4px;
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    line-height: 42px;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
}
.fangshirs {
    width: 343px;
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    margin: 0 auto;
    margin-top: 20px;
}
.resident >>> .van-field--disabled .van-field__label {
    color: #646566;
}
.gerenrz {
    height: 44px;
    background: #ffffff;
    font-size: 17px;
    font-family: Noto Sans SC, Noto Sans SC-700;
    font-weight: 700;
    text-align: center;
    line-height: 44px;
    color: #202020;
    /* border-bottom: 0.8px solid #f0f0f0; */
    margin-bottom: 10px;
}
</style>