import Vue from 'vue'
import router from '../router'
// import api from '@/api/onlineSchool'
import store from '@/store/store'
import { Toast } from 'vant'
import html2canvas from 'html2canvas'

/**
 * 描述：深拷贝
 * @param   : target {object}
 * @return  : {object} 新对象
**/
export function deepClone(target) {
    // 定义一个变量
    let result
    // 如果当前需要深拷贝的是一个对象的话
    if (typeof target === 'object') {
    // 如果是一个数组的话
        if (Array.isArray(target)) {
            result = [] // 将result赋值为一个数组，并且执行遍历
            for (let i in target) {
                // 递归克隆数组中的每一项
                result.push(deepClone(target[i]))
            }
            // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
            result = null
            // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
            result = target
        } else {
            // 否则是普通对象，直接for in循环，递归赋值对象的所有值
            result = {}
            for (let i in target) {
                result[i] = deepClone(target[i])
            }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
    } else {
        result = target
    }
    // 返回最终结果
    return result
}


/**
 * 描述：魔方网校配置资源跳转
 * @param   : type {string} 1：直播课程 2：素材资料 3：自定义链接
 * @param   : isLive {string} 0：非直播课 1：直播课  
 * @param   : url {string} 自定义链接地址
 * @param   : detail_id {string} 直播间id / 课程id
**/
export function gotoPageByConfig({ id, type, isLive, ds_id, live_type, url, detail_id, material_type, course_id  }) {
    console.log({ type, isLive, ds_id, live_type, url, detail_id, material_type, course_id  });
    if(type == '1') {
        if(isLive == '1') {
            // 直播课
            // window.location.href = `${Vue.prototype.$wx_h5_url}?ds_id=${detail_id}`
            if(localStorage.getItem('token')) {
                if(live_type === '2') {
                    // 跳转班课直播间
                    getCourseLiveToken(ds_id)
                } else if(live_type === '3'){
                    // 跳转伪直播
                    getFakeLiveToken(id, ds_id)
                } else {
                    // 跳转直播
                    getLiveToken(ds_id)
                }
            } else {
                // 记录当前页面地址，方便登登录后重定向
		        const redirectUrl = location.href
                router.push({ name: 'zhanghaomimadl', query: { redirectUrl: redirectUrl } })
            }
        } else {
            if(live_type === '5') {
                console.log('{ setid: course_id, goodsId: detail_id }: ', { setid: course_id, goodsId: detail_id })
                // 跳转商品课程详情
                router.push({ path: '/zhibokecheng', query:{ setid: course_id, goodsId: detail_id } })
            } else {
                // 跳转非商品课程详情
                router.push({ path: '/zhibo', query:{ setid: detail_id } })
            }
        }
    } else if(type == '2') {
        // 素材资料
        if(material_type === 1) {
            // 下载文件
            // downloadFile(url)
            window.location.href = url
        } else {
            // 跳转自定义链接
            window.location.href = url
        }
    } else if(type == '3') {
        // 跳转自定义链接
        window.location.href = url
    }
}

function downloadFile(url) {
    Vue.prototype.$api({
        method: "get",
        url,
        headers: {
            "content-type": "application/json; charset=utf-8",
        },
        responseType: "blob",
    }).then(({ data }) => {
        // 截取url最后几位作为文件名（https://tengface-live-user-1308721187.cos.accelerate.myqcloud.com/1689928385757bug.txt）
        const fileName = url.split('/').slice(-1)[0].slice(13)
        convertResToBlob(data, fileName)
    })
}

export function convertResToBlob(response, fileName) {
    // 将二进制流转为blob
    const blob = new Blob([response], { type: 'application/octet-stream' })
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
      window.navigator.msSaveBlob(blob, decodeURI(fileName))
    } else {
      // 创建新的URL并指向File对象或者Blob对象的地址
      const blobURL = window.URL.createObjectURL(blob)
      // 创建a标签，用于跳转至下载链接
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      tempLink.setAttribute('download', decodeURI(fileName))
      // 兼容：某些浏览器不支持HTML5的download属性
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }
      // 挂载a标签
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      // 释放blob URL地址
      window.URL.revokeObjectURL(blobURL)
    }
}


/**
 * 描述：跳转直播链接
 **/
function createToLiveUrl(dsId,token) {
    return `${Vue.prototype.$wx_h5_url}?ds_id=${dsId}&token=${token}
        &other=1&shool_token=${localStorage.getItem('token')}`
}

/**
 * 描述：跳转直播获取token
**/
async function getLiveToken(dsId) {
    const dataParams = {
        org_id: store.getters['user/getOrgId'],
        fans_id: store.getters['user/getFansId']
    }
    const { data: { code, data:responseData } } = await Vue.prototype.$api.post('/stu/course/getdstoken', dataParams)
    if(code === 200) {
        const { liveToken } = responseData
        // ${Vue.prototype.$wx_h5_url}
        window.location.href = `https://u.shkt.online?ds_id=${dsId}&token=${liveToken}&other=1&shool_token=${localStorage.getItem('token')}`
    }
}

/**
 * 描述：描述：跳转伪直播获取token
**/
async function getFakeLiveToken(id, dsId) {
    const dataParams = {
        org_id: store.getters['user/getOrgId'],
        fans_id: store.getters['user/getFansId']
    }
    const { data: { code, data:responseData } } = await Vue.prototype.$api.post('/stu/course/getdstoken', dataParams)
    if(code === 200) {
        const { liveToken } = responseData
        window.location.href = 
            `https://u.shkt.online/f/?ds_id=${dsId}&tid=${id}&token=${liveToken}&other=1&shool_token=${localStorage.getItem('token')}`
        
    }
}

/**
 * 描述：跳转班课直播间获取token
**/
async function getCourseLiveToken(dsId) {
    const dataParams = {
        ds_id: dsId,
        fans_id: store.getters['user/getFansId']
    }
    const { data: { code, data:responseData, message } } = await Vue.prototype.$api.post('/stu/course/dsAuth', dataParams)
    if(code === 200) {
        const { liveToken } = responseData
        window.location.href = `${Vue.prototype.$wx_h5_url}?ds_id=${dsId}&token=${liveToken}&other=1&shool_token=${localStorage.getItem('token')}`
    } else {
        Toast(message || '请求异常')
    }
}

/**
 * 描述：防抖函数
 * @param   : fn {function} , wait {number}
**/
export function debounce(fn, wait) {
    let timer;
    return function() {
        const args = arguments
        clearTimeout(timer)
        timer = setTimeout(() =>{
            fn.apply(this, args)
        }, wait)
    };
}

/**
 * 描述：将html转换成图片
 * @param   : element {HTMLElment} 需要转成png的dom
 * @return  : {Promise} 
**/
export function htmlToPng(element) {
    const options = {
        useCORS: true,
        backgroundColor: '#ffffff',
        // allowTaint: true,
        scale: 2,
        dpi: 300,
    }
    return new Promise((resolve, reject) => {
        try {
            html2canvas(element, options).then(canvas => {
                const link = canvas.toDataURL('image/png')
                resolve(link)
            })
        } catch (error) {
            reject(error)
        }
    })
}

/**
 * 描述：将base64转换为blob
 * @param   : dataurl {base64格式}
 * @return  : {Blob} blob格式的文件
**/
export function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
}

/**
 * 描述：将blob转换为file
 * @param   : theBlob {Blob} , fileName {string}
 * @example : {file} 文件对象
**/
export function blobToFile(theBlob, fileName){
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
}

/**
 * 描述：获取系统日期，格式：2023-09-23
**/
export function getSystemDate() {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    return `${year}年${month}月${day}日`
}

export const secondsToTimeFormat = (seconds) => {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var secs = seconds % 60;
 
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    secs = secs < 10 ? '0' + secs : secs;
 
    return hours + ':' + minutes + ':' + secs;
}

/**
 * 描述：获取机构域名
**/
export function getOrgDomain() {
    if(process.env.NODE_ENV === 'development') {
        return process.env.VUE_APP_DOMAIN_URL
    }
    if(!location.host.includes('contract')) {
        return window.location.host
    }
    // 先看url是否携带了机构域名
    const domain = getHashSearchParam('domain')
    if(domain){
        // 读取url携带的机构域名（后台合同链接会携带用户的自定义域名）
        // let params = location.hash.split('?')[1]
        // params = params.split('&').find(item => item.includes('domain'))
        // params = params.split('=')[1]
        store.commit('user/setOrgDoamin', domain)
        return domain
    } else {
        // url没有域名则从缓存中取
        return store.getters['user/getDomain'] || window.location.host
    }
}

export function getHashSearchParam(key) {
    const url = location.href
    const hash = url.substring(url.indexOf("#") + 1)
    const searchIndex = hash.indexOf("?")
    const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : ""
    const usp = new URLSearchParams(search)
    return usp.get(key)
}